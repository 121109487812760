import assetMap from 'frontend-login/utils/asset-map';

/**
 * Import an asset with fingerprinting.
 * @param param0 the image path.
 * @returns the reference to the asset.
 * @example
 * // Returns the reference to the asset, e.g. `/assets/images/logo-1234567890abcdef.svg`
 * // In a template
 * {{import-asset 'images/logo.svg'}}
 * // In JavaScript/TypeScript
 * importAsset('images/logo.svg')
 */
export default function importAsset(assetPath: (typeof assetMap)[string]): string {
  return assetMap[`../static/${assetPath}`] as string;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'import-asset': typeof importAsset;
  }
}
