import Service from '@ember/service';
import fetch from 'fetch';

import { getHostDomain } from 'frontend-utils/utils/domain-checks';

export default class FetchService extends Service {
  /**
   * Formats a url by adding the host domain and formatted query parameters.
   * @param {string} route The route path.
   * @param {object} parameters Key-value object specifying query parameters.
   * @returns {string}
   */
  formatUrl(route, parameters) {
    let newRoute = getHostDomain() + route;

    if (parameters && Object.keys(parameters).length) {
      newRoute += '?';
      Object.keys(parameters).forEach((p) => {
        newRoute += `${encodeURIComponent(p)}=${encodeURIComponent(parameters[p])}`;
        if (Object.keys(parameters).indexOf(p) !== Object.keys(parameters).length - 1) {
          newRoute += '&';
        }
      });
    }
    return newRoute;
  }

  async fetch(route, parameters) {
    const formattedUrl = this.formatUrl(route, parameters);
    const response = await fetch(formattedUrl);
    return response.json();
  }

  async put(route, parameters, payload) {
    const formattedUrl = this.formatUrl(route, parameters);
    const response = await fetch(formattedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    let data = null;
    if (response.json()) {
      data = await response.json();
    }

    return { response, data };
  }

  async post(route, parameters, payload, contentType = 'json') {
    // Prepare payload for sending
    let body;
    let contentTypeHeader;
    if (contentType === 'json') {
      contentTypeHeader = 'application/json';
      body = JSON.stringify(payload);
    } else if (contentType === 'form') {
      contentTypeHeader = 'application/x-www-form-urlencoded';
      const bodyParts = [];
      Object.keys(payload).forEach((key) => {
        bodyParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`);
      });
      body = bodyParts.join('&');
    }

    const formattedUrl = this.formatUrl(route, parameters);
    const response = await fetch(formattedUrl, {
      method: 'POST',
      headers: {
        'Content-Type': contentTypeHeader,
        'X-CSRFToken': document.querySelector('input[name="csrfmiddlewaretoken"]')?.value,
      },
      body,
    });

    // For the form requests, dont parse response as it isn't json anyways
    if (contentType === 'form') {
      return { response, body: response };
    }

    const data = await response.json();
    return { response, data };
  }
}
