import Route from '@ember/routing/route';

interface ChangeEmailRouteParams {
  code: string;
}

export default class ChangeEmailRoute extends Route {
  model(params: ChangeEmailRouteParams) {
    return {
      changeEmailCode: params.code,
    };
  }
}
