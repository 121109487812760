import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { EMAIL_REGEX } from 'frontend-login/utils/constant-values';

export default class PasswordResetController extends Controller {
  queryParams = ['email'];

  @service router;

  @service('fetch') fetchService;

  @tracked email = '';

  @tracked isEmailValid = true;

  @tracked userHasErrors = false;

  @tracked success = false;

  validateEmail() {
    this.isEmailValid = this.email.match(EMAIL_REGEX);
  }

  @action
  setEmail(event) {
    this.email = event.target.value;
    this.isEmailValid = true;
  }

  @action
  goBack() {
    this.success = false;
    this.router.transitionTo('login');
  }

  @action
  submitFormWithEnter(key) {
    if (key.keyCode === 13) {
      this.resetPassword();
    }
  }

  @action
  async resetPassword() {
    this.validateEmail();
    if (!this.isEmailValid) {
      return;
    }

    try {
      await this.fetchService.post('/password-reset/', null, { email: this.email }, 'form');
      this.success = true;
    } catch (error) {
      this.isEmailValid = false;
    }
  }
}
