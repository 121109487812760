export const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

export const AUTHENTICATION = 'authentication';

export const SSO_TYPES = {
  GOOGLE: 'google',
  MICROSOFT: 'microsoft',
  LINKEDIN: 'linkedin',
  SAML: 'saml',
} as const;

export type SSOType = (typeof SSO_TYPES)[keyof typeof SSO_TYPES];

export const GIP_PROVIDER_TYPES = {
  [SSO_TYPES.GOOGLE]: 'google.com',
  [SSO_TYPES.MICROSOFT]: 'microsoft.com',
  [SSO_TYPES.LINKEDIN]: 'linkedin.com',
  [SSO_TYPES.SAML]: 'saml',
};

export const GIP_NAMES = {
  PASSWORD: 'password',
  GOOGLE: 'google.com',
  MICROSOFT: 'microsoft.com',
  LINKEDIN: 'linkedin.com',
  SAML: 'saml',
} as const;

export type GIPName = (typeof GIP_NAMES)[keyof typeof GIP_NAMES];

export const GIP_NAMES_TRANSLATIONS = {
  [GIP_NAMES.PASSWORD]: 'login.gip.email_and_password',
  [GIP_NAMES.GOOGLE]: 'Google',
  [GIP_NAMES.MICROSOFT]: 'Microsoft',
  [GIP_NAMES.LINKEDIN]: 'LinkedIn',
  [GIP_NAMES.SAML]: 'SAML',
};

// This is a subset of GIP_NAMES that does not include the password provider. Currently LinkedIn is not supported so it is also excluded.
export const GIP_NAMES_WITHOUT_PASSWORD = {
  GOOGLE: GIP_NAMES.GOOGLE,
  MICROSOFT: GIP_NAMES.MICROSOFT,
  SAML: GIP_NAMES.SAML,
} as const;

export type GIPNameWithoutPassword =
  (typeof GIP_NAMES_WITHOUT_PASSWORD)[keyof typeof GIP_NAMES_WITHOUT_PASSWORD];

export const PROVIDER_TYPES = {
  EMAIL_AND_PASSWORD: 'email_and_password',
  SAML: 'saml',
  SOCIAL: 'social',
} as const;

export type ProviderType = (typeof PROVIDER_TYPES)[keyof typeof PROVIDER_TYPES];

export const RESPONSE_CODE = {
  OK: 'sso.info.ok',
  MFA_SENT: 'sso.info.2fa_sent',
  PROVIDER_CONFIRMATION_REQUIRED: 'sso.info.provider_confirmation_required',
  INVALID_CREDENTIALS: 'sso.error.invalid_credentials',
  GENERIC: 'sso.error.generic',
  ACCOUNT_ISSUE: 'sso.error.account_issue',
  LOGIN_OPTION_UNAVAILABLE: 'sso.error.login_option_unavailable',
  LOGIN_OPTION_NOT_FOUND: 'sso.error.login_option_not_found',
  EMAIL_ALREADY_EXISTS: 'sso.error.email_already_exists',
};

export const FIREBASE_ERROR_CODES = {
  INVALID_CREDENTIALS: 'auth/invalid-credential',
};
