import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';

import type { LoginResponse } from 'frontend-login/services/authentication';
import type AuthenticationService from 'frontend-login/services/authentication';
import type FetchService from 'frontend-login/services/fetch';
import {
  EMAIL_REGEX,
  PROVIDER_TYPES,
  SSO_TYPES,
  RESPONSE_CODE,
  GIP_NAMES,
  GIP_NAMES_TRANSLATIONS,
  type GIPName,
  FIREBASE_ERROR_CODES,
  type ProviderType,
  AUTHENTICATION,
  GIP_NAMES_WITHOUT_PASSWORD,
  type GIPNameWithoutPassword,
} from 'frontend-login/utils/constant-values';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import { getHostDomain } from 'frontend-utils/utils/domain-checks';

/* eslint-disable @typescript-eslint/naming-convention */
interface LoginOption {
  identity_provider: {
    name: string;
    provider_type: string;
    gip_name: GIPName;
    location?: string;
  };
  type: string;
  location: string;
  is_migrated: boolean;
}

interface LoginOptionResponse {
  login_options: LoginOption[];
}
/* eslint-enable @typescript-eslint/naming-convention */

interface StorageData {
  connectWithNewPlatformInProcess: boolean;
  newPlatformToken?: string | undefined;
}

export default class LoginController extends Controller {
  queryParams = [
    'firstName',
    'isSsoEnforced',
    'email',
    'next',
    'spiderMigrationMessage',
    'emailVerficationCode',
    'idp',
    'isRedirecting',
    'showGenericError',
  ];

  @service declare fetch: FetchService;

  @service declare authentication: AuthenticationService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare intl: IntlService;

  @tracked firstName = '';

  @tracked email = '';

  @tracked emailInputValue = '';

  @tracked password = '';

  @tracked repeatPassword = '';

  @tracked isNewPasswordValid = false;

  @tracked loginOptions: LoginOption[] | null = null;

  @tracked spiderMigrationMessage = null;

  @tracked isSsoEnforced = false;

  @tracked idp = null;

  @tracked showSSOConfirmationScreen = false;

  @tracked showGenericError = false;

  @tracked showError = false;

  @tracked errorTitle = '';

  @tracked errorMessage = '';

  @tracked isRegistrationFlow = false;

  @tracked connectWithNewPlatformConfirmation = false;

  @tracked emailVerficationCode = '';

  @tracked verificationNeeded = false;

  @tracked next: string | undefined;

  @tracked isRedirecting = false;

  @tracked loadingButtonType: null | GIPName = null;

  @tracked isRequestRunning = false;

  @tracked emailInputHasErrors = false;

  // TODO-MFA Remove this when new 2FA is ready
  @tracked show2FAScreen = false;

  // This variable is used before redirecting to distinguish which redirecting message should be shown
  @tracked isBeingRedirected = false;

  token: string | undefined;

  newPlatformToken: string | undefined;

  connectWithNewPlatformInProcess = false;

  activeLoginOption: LoginOption | undefined;

  activeGipName: GIPName | undefined;

  oldGipName: GIPName | undefined;

  signInActions = {
    [GIP_NAMES.GOOGLE]: this.signInWithGoogle,
    [GIP_NAMES.MICROSOFT]: this.signInWithMicrosoft,
    [GIP_NAMES.SAML]: this.signInWithSaml,
  };

  initializeErrorHandling = modifier(() => {
    if (this.showGenericError) {
      this.showErrorMessage();
    }
  });

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    this.authentication.handleRedirect().then(
      (result: LoginResponse | null) => this.handleRedirectLogic(result, null),
      (error: unknown) => this.handleRedirectLogic(null, error),
    );
  }

  async handleRedirectLogic(result: LoginResponse | null, error: unknown | null = null) {
    if (!error && !result) {
      if (!this.isBeingRedirected) {
        this.isRedirecting = false;
      }
      return;
    }

    if (error) {
      this.isRedirecting = false;
      this.showErrorMessage();
      this.errorTracking.captureException(error);
      return;
    }

    if (!result) {
      this.isRedirecting = false;
      this.showErrorMessage();
      return;
    }

    if (!result.response.ok) {
      this.isRedirecting = false;
      this.handleErrors(result);
      return;
    }

    // Store the token for if we need to connect with a new platform
    if (result.token) {
      this.token = result.token;
    }

    // Restore email input value and login options
    this.emailInputValue = result.email || this.emailInputValue;
    await this.fetchLoginOptions(false);

    // Get the data that was stored in localStorage before being redirected to the authentication method
    // The data is used to continue the flow after the user has selected a new platform
    const storageData = localStorage.getItem(AUTHENTICATION);
    if (storageData) {
      const data = JSON.parse(storageData);
      localStorage.removeItem(AUTHENTICATION);
      this.connectWithNewPlatformInProcess = data.connectWithNewPlatformInProcess;
      this.newPlatformToken = data.newPlatformToken;
    }

    if (this.connectWithNewPlatformInProcess) {
      this.isRedirecting = false;
      this.connectWithNewPlatformInProcess = false;
      this.confirmNewPlatform();
      return;
    }
    if (!this.newPlatformToken) {
      this.newPlatformToken = result.token;
    }

    this.continueFlow(result, true);
  }

  storeLocalStorage(data: StorageData) {
    localStorage.setItem(AUTHENTICATION, JSON.stringify(data));
  }

  isSignInActionType(idp: GIPName | ProviderType): idp is GIPNameWithoutPassword {
    return Object.values(GIP_NAMES_WITHOUT_PASSWORD).includes(idp as GIPNameWithoutPassword);
  }

  redirectToIdp(idp: GIPName | ProviderType) {
    this.isRedirecting = true;
    this.isBeingRedirected = true;

    if (this.isSignInActionType(idp)) {
      this.signInActions[idp]();
    } else if (idp.startsWith('saml.')) {
      this.signInWithSaml(GIP_NAMES.SAML, idp);
    } else {
      this.isRedirecting = false;
      this.showErrorMessage();
    }
  }

  handleErrors(result: LoginResponse, isPasswordSignIn = false) {
    const { status } = result.response;
    const { code } = result.data;

    if (code === RESPONSE_CODE.INVALID_CREDENTIALS && isPasswordSignIn) {
      this.showErrorMessage(null, 'login.error.invalid_credentials');
      return;
    }

    if (code === RESPONSE_CODE.INVALID_CREDENTIALS && !isPasswordSignIn) {
      this.showErrorMessage(null, 'login.error.no_access');
      return;
    }

    if (code === RESPONSE_CODE.EMAIL_ALREADY_EXISTS) {
      this.showErrorMessage(null, 'login.error.email_already_exists');
      return;
    }

    if (
      code === RESPONSE_CODE.LOGIN_OPTION_UNAVAILABLE ||
      code === RESPONSE_CODE.ACCOUNT_ISSUE ||
      code === RESPONSE_CODE.LOGIN_OPTION_NOT_FOUND ||
      code === RESPONSE_CODE.GENERIC
    ) {
      this.showErrorMessage();
      this.errorTracking.captureException(result.data);
      return;
    }

    if (status === 500) {
      this.showErrorMessage();
      this.errorTracking.captureException(result.response);
    }
  }

  async fetchLoginOptions(redirect = true) {
    try {
      const response = await this.fetch.fetch<LoginOptionResponse>('/sso/login-options/', {
        email: this.emailInputValue,
      });
      this.loginOptions = response.login_options;

      // If there is only one option, redirect to it
      if (redirect && this.loginOptions?.length === 1 && !this.hasOnlyPasswordLoginOption) {
        const identityProvider = this.loginOptions[0]?.identity_provider;
        const providerType =
          identityProvider?.provider_type === PROVIDER_TYPES.SAML
            ? identityProvider.provider_type
            : identityProvider?.gip_name;
        if (providerType) {
          this.redirectToIdp(providerType);
        }
      }
    } catch (error) {
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  async signInWithPassword() {
    this.isRequestRunning = true;
    try {
      if (!this.passwordLoginOption) {
        throw new Error('No password login option available');
      }

      let result: LoginResponse;
      const params = {
        next: this.nextWithDefault,
      };
      // If the user is not migrated, we need to migrate them first through UM
      if (!this.passwordLoginOption.is_migrated) {
        result = await this.fetch.post('/sso/login-and-migrate/', params, {
          username: this.emailInputValue,
          password: this.password,
        });
      } else {
        result = await this.authentication.signInWithPassword(
          this.emailInputValue,
          this.password,
          params,
        );
        if (result.token) {
          this.token = result.token;
        }
      }

      this.isRequestRunning = false;

      if (!result.response.ok) {
        this.handleErrors(result, true);
        return;
      }

      this.continueFlow(result);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.isRequestRunning = false;
      if (error.code === FIREBASE_ERROR_CODES.INVALID_CREDENTIALS) {
        this.showErrorMessage(null, 'login.error.invalid_credentials');
      } else {
        this.showErrorMessage();
      }
      this.errorTracking.captureException(error);
    }
  }

  async signUpWithPassword() {
    this.isRequestRunning = true;
    let result: LoginResponse;
    const params = {
      next: this.nextWithDefault,
    };
    try {
      result = await this.fetch.post('/sso/register/', params, {
        email: this.emailInputValue,
        password: this.password,
        email_verification_code: this.emailVerficationCode,
      });

      if (!result.response.ok) {
        this.isRequestRunning = false;
        this.handleErrors(result, true);
        return;
      }

      result = await this.authentication.signInWithPassword(
        this.emailInputValue,
        this.password,
        params,
      );
      this.isRequestRunning = false;

      if (!result.response.ok) {
        this.handleErrors(result, true);
        return;
      }

      if (result.token) {
        this.token = result.token;
      }

      this.continueFlow(result);
    } catch (error) {
      this.isRequestRunning = false;
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  async continueFlow(response: LoginResponse, afterRedirect = false) {
    const { code, next, meta } = response.data;

    if (code === RESPONSE_CODE.OK) {
      if (this.connectWithNewPlatformInProcess && this.activeLoginOption && this.activeGipName) {
        await this.confirmNewPlatform();
      }

      const host = getHostDomain();
      const decodedNext = next ? decodeURIComponent(next) : '';
      if (decodedNext && decodedNext.startsWith('/')) {
        window.location.href = `${host}${next}`;
      }
    } else if (code === RESPONSE_CODE.MFA_SENT) {
      this.show2FAScreen = true;
    } else if (code === RESPONSE_CODE.PROVIDER_CONFIRMATION_REQUIRED && meta?.['login_option']) {
      this.isRedirecting = false;

      this.activeLoginOption = meta['login_option'] as LoginOption;
      this.activeGipName = (meta['login_option'] as LoginOption).identity_provider.gip_name;

      // If the user is already in the process of connecting with a new platform, we need to confirm the new platform
      if (this.connectWithNewPlatformInProcess) {
        await this.confirmNewPlatform();
        return;
      }

      // If the user is in the process of connecting with a new platform, we need to show the connect with new platform screen,
      // otherwise show the SSO confirmation screen
      if (afterRedirect) {
        if (this.loginOptions) {
          this.oldGipName = this.loginOptions[0]?.identity_provider.gip_name;
        }
        this.connectWithNewPlatformConfirmation = true;
      } else {
        this.showSSOConfirmationScreen = true;
      }
    }
  }

  get pageTitle() {
    if (this.isRegistrationFlow) {
      return this.intl.t('login.sign_up');
    }

    return this.intl.t('login.sign_in');
  }

  get isEmailValid() {
    return EMAIL_REGEX.test(this.emailInputValue);
  }

  get hasOnlyPasswordLoginOption() {
    return this.loginOptions?.length === 1 && this.passwordLoginOption;
  }

  get userHasloginOptions() {
    return this.loginOptions?.length;
  }

  get ssoLoginOptions() {
    // Add default social login options if there are none
    if (!this.loginOptions?.length && !this.hasOnlyPasswordLoginOption) {
      return [
        {
          type: SSO_TYPES.GOOGLE,
          gipType: GIP_NAMES.GOOGLE,
          onClickAction: () => {
            this.signInWithGoogle(GIP_NAMES.GOOGLE);
          },
        },
        {
          type: SSO_TYPES.MICROSOFT,
          gipType: GIP_NAMES.MICROSOFT,
          onClickAction: () => this.signInWithMicrosoft(GIP_NAMES.MICROSOFT),
        },
      ];
    }

    if (!this.loginOptions) {
      return [];
    }

    const filteredLoginOptions = this.loginOptions.filter(
      (option) => option.identity_provider.provider_type !== PROVIDER_TYPES.EMAIL_AND_PASSWORD,
    );
    return filteredLoginOptions.map((option: LoginOption) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const { provider_type, gip_name, name } = option.identity_provider;
      const isSaml = provider_type === PROVIDER_TYPES.SAML;

      const gipType: GIPName = isSaml ? GIP_NAMES.SAML : gip_name;
      const type = isSaml ? PROVIDER_TYPES.SAML : name.toLowerCase();
      /* eslint-enable @typescript-eslint/naming-convention */
      return {
        type,
        onClickAction: () =>
          this.signInActions[gipType as keyof typeof this.signInActions](gipType),
      };
    });
  }

  get dividerText() {
    return this.isRegistrationFlow
      ? this.intl.t('login.or_sign_up_with')
      : this.intl.t('login.or_sign_in_with');
  }

  get title() {
    if (this.verificationNeeded) {
      return this.intl.t('login.verify_account_title');
    }
    return this.firstName
      ? this.intl.t('login.welcome_user', {
          firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1),
        })
      : this.intl.t('login.title');
  }

  get passwordLoginOption(): LoginOption | undefined {
    if (!this.loginOptions?.length) {
      return undefined;
    }

    return this.loginOptions.find(
      (option) => option.identity_provider.provider_type === PROVIDER_TYPES.EMAIL_AND_PASSWORD,
    );
  }

  get samlLoginOption() {
    return (
      this.loginOptions?.find(
        (option) => option.identity_provider.provider_type === PROVIDER_TYPES.SAML,
      ) ?? this.activeLoginOption
    );
  }

  get showPasswordLogin() {
    return !this.isRegistrationFlow && this.passwordLoginOption && !this.showSSOConfirmationScreen;
  }

  get isRepeatPasswordValid() {
    return this.password === this.repeatPassword;
  }

  get submitButtonLabel() {
    if (this.isSsoEnforced) {
      return this.intl.t('login.next');
    }

    if (this.isRegistrationFlow) {
      return this.intl.t('login.sign_up');
    }

    return this.intl.t('login.sign_in');
  }

  get arePasswordsValid() {
    return this.isNewPasswordValid && this.isRepeatPasswordValid;
  }

  get isFormValid() {
    if (this.isRegistrationFlow && !this.isSsoEnforced) {
      return this.isNewPasswordValid && this.isEmailValid && this.isRepeatPasswordValid;
    }

    return this.isEmailValid;
  }

  get showSubmitButton() {
    return !this.loginOptions || this.passwordLoginOption || this.isSsoEnforced;
  }

  get isSubmitButtonDisabled() {
    return (
      this.isRequestRunning ||
      !this.emailInputValue ||
      (this.shouldRenderPasswordInput && !this.password)
    );
  }

  get shouldRenderPasswordInput() {
    return this.passwordLoginOption && !this.showSSOConfirmationScreen && !this.isRegistrationFlow;
  }

  get newPlatformConfirmationText() {
    if (!this.oldGipName || !this.activeGipName) {
      return '';
    }
    return this.intl.t('login.change_platform_message', {
      oldPlatform:
        this.oldGipName === GIP_NAMES.PASSWORD
          ? this.intl.t(GIP_NAMES_TRANSLATIONS[this.oldGipName])
          : GIP_NAMES_TRANSLATIONS[this.oldGipName],
      newPlatform:
        this.activeGipName === GIP_NAMES.PASSWORD
          ? this.intl.t(GIP_NAMES_TRANSLATIONS[this.activeGipName])
          : GIP_NAMES_TRANSLATIONS[this.activeGipName],
    });
  }

  /*
   * Use / as fallback for the next param if it's empty
   * or if it's not a valid URL (i.e. doesn't start with a / which could lead to a wrong redirect)
   */
  get nextWithDefault() {
    if (this.next && this.next.startsWith('/')) {
      return this.next;
    }

    return '/';
  }

  get showLoadingScreen() {
    return this.isRedirecting && !this.loadingButtonType;
  }

  @action
  setEmail(event: Event) {
    // Reset the login options if the user changes the email
    if (this.loginOptions) {
      this.loginOptions = null;
      this.password = '';
    }
    this.emailInputHasErrors = false;
    this.emailInputValue = (event.target as HTMLInputElement)?.value;
  }

  @action
  setPassword(event: Event) {
    this.password = (event.target as HTMLInputElement)?.value;
  }

  @action
  setRepeatPassword(event: Event) {
    this.repeatPassword = (event.target as HTMLInputElement)?.value;
  }

  @action
  submitForm() {
    if (this.isFormValid) {
      this.showError = false;
      this.emailInputHasErrors = false;

      if (this.isRegistrationFlow) {
        if (this.password) {
          this.signUpWithPassword();
        }

        if (this.isSsoEnforced && this.ssoLoginOptions[0]) {
          this.ssoLoginOptions[0].onClickAction();
        }
      } else {
        if (this.isEmailValid && !this.userHasloginOptions) {
          this.fetchLoginOptions();
        }

        if (this.userHasloginOptions && this.password !== '') {
          this.signInWithPassword();
        }
      }
    }

    if (!this.isEmailValid) {
      this.emailInputHasErrors = true;
    }
  }

  @action
  submitFormWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submitForm();
    }
  }

  @action
  async signInWithGoogle(gipName: GIPName | null = null) {
    try {
      if (gipName) {
        this.loadingButtonType = gipName;
      }
      this.isRedirecting = true;
      this.storeLocalStorage({
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
        newPlatformToken: this.newPlatformToken,
      });

      await this.authentication.signInWithGoogle();
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  @action
  async signInWithMicrosoft(gipName: GIPName | null = null) {
    try {
      if (gipName) {
        this.loadingButtonType = gipName;
      }
      this.isRedirecting = true;
      this.storeLocalStorage({
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
      });

      await this.authentication.signInWithMicrosoft();
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  @action
  async signInWithSaml(providerType: GIPName | null = null, gipName: string | null = null) {
    try {
      if (providerType) {
        this.loadingButtonType = providerType;
      }
      this.isRedirecting = true;
      const location = this.samlLoginOption?.identity_provider.location;
      if (location && location.startsWith('/')) {
        window.location.href = location;
        return;
      }
      this.storeLocalStorage({
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
      });
      if (!this.samlLoginOption && !gipName) {
        this.showErrorMessage();
        return;
      }

      const gip = gipName || this.samlLoginOption?.identity_provider.gip_name;
      if (!gip) {
        throw new Error('GIP name is undefined');
      }
      await this.authentication.signInWithSaml(gip);
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  @action
  setNewPasswordValidState(isValid: boolean) {
    this.isNewPasswordValid = isValid;
  }

  @action
  denySSOConfirmation() {
    this.showSSOConfirmationScreen = false;
    this.showError = true;
    this.errorMessage = this.intl.t('login.error.deny_sso');
  }

  @action
  async confirmNewPlatform() {
    this.showSSOConfirmationScreen = false;
    try {
      if (!this.token || !this.activeLoginOption || !this.activeGipName) {
        throw new Error('Token or gip is undefined');
      }
      const result = await this.authentication.acceptSso(this.token, this.activeGipName);
      if (this.newPlatformToken) {
        const loginResult = await this.authentication.verifyTokenOnUM(this.newPlatformToken);
        this.connectWithNewPlatformInProcess = false;
        this.continueFlow(loginResult);
      }
      // Redirect to the new platform
      if (result.ok && this.activeLoginOption) {
        const idp =
          this.activeLoginOption.identity_provider.provider_type === PROVIDER_TYPES.SAML
            ? PROVIDER_TYPES.SAML
            : this.activeLoginOption.identity_provider.gip_name;
        this.redirectToIdp(idp);
        this.connectWithNewPlatformInProcess = false;
        return;
      }
      this.connectWithNewPlatformInProcess = false;

      // Error handling
      const data: LoginResponse = await result.json();
      this.handleErrors(data);
    } catch (error) {
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  @action
  cancelConnectWithNewPlatform() {
    this.connectWithNewPlatformConfirmation = false;
  }

  @action
  applyConnectWithNewPlatform() {
    this.connectWithNewPlatformConfirmation = false;
    this.connectWithNewPlatformInProcess = true;
    this.verificationNeeded = true;
    this.submitForm();
  }

  @action
  showErrorMessage(
    title: string | null = 'general.error_title',
    message: string | null = 'login.error.general',
  ) {
    this.errorMessage = '';
    this.errorTitle = '';
    if (title) {
      this.errorTitle = this.intl.t(title);
    }
    if (message) {
      this.errorMessage = this.intl.t(message);
    }
    this.showError = true;
    this.isRedirecting = false;
  }

  @action
  reset() {
    this.emailInputValue = '';
    this.password = '';
    this.repeatPassword = '';
    this.loginOptions = null;
    this.show2FAScreen = false;
    this.showError = false;
  }
}
