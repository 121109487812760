type ImageAssetFileExtension = 'svg' | 'png' | 'jpg' | 'jpeg' | 'gif';
type ImageAssetFilePath = `images/${string}.${ImageAssetFileExtension}`;

/*
 * The folder is currently named `static` because `assets` is a reserved folder name in Embroider.
 * There's an open issue for this (https://github.com/embroider-build/embroider/issues/2087#issue-2497405100)
 * and we should probably rename the folder to `assets` once the issue is resolved.
 */
const assets = import.meta.glob<ImageAssetFilePath>('../static/**/*.*', {
  eager: true,
});

export default assets;
