import getDomainName from 'frontend-utils/utils/domain-utils';

export const PLATFORM_STAGING_DOMAIN = `test.${getDomainName()}`;
export const PLATFORM_DEVELOPMENT_DOMAIN = 'localhost';

export function isPlatformDevelopmentDomain() {
  return window.location.hostname === PLATFORM_DEVELOPMENT_DOMAIN;
}

export function getHostName() {
  if (isPlatformDevelopmentDomain()) {
    return `${PLATFORM_STAGING_DOMAIN}`;
  }

  return `${window.location.hostname}`;
}
