import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

import type LoginController from 'frontend-login/controllers/login';
import LoginRoute from 'frontend-login/routes/login';

export default class RegisterRoute extends LoginRoute {
  @service declare router: RouterService;

  templateName = 'login';

  controllerName = 'register';

  beforeModel(transition: Transition) {
    if (!transition.to.queryParams['email']) {
      this.router.transitionTo('/login');
    }
  }

  setupController(controller: LoginController, transition: Transition) {
    super.setupController(controller, transition);

    if (controller.email) {
      controller.fetchLoginOptions();
    }
  }
}
