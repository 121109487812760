import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  OAuthProvider,
  type Auth,
  SAMLAuthProvider,
  getRedirectResult,
} from 'firebase/auth';

import ENV from 'frontend-login/config/environment';
import type FetchService from 'frontend-login/services/fetch';
import type { GIPName } from 'frontend-login/utils/constant-values';
import { getHostName } from 'frontend-login/utils/domain-checks';
import { getHostDomain } from 'frontend-utils/utils/domain-checks';

export interface LoginResponse {
  response: Response;
  data: {
    next?: string;
    code: string;
    title?: string;
    detail?: string;
    meta?: Record<string, unknown>;
  };
  email?: string | null;
  token?: string;
}

export default class AuthenticationService extends Service {
  @service('fetch') declare fetchService: FetchService;

  @service declare router: RouterService;

  auth: Auth;

  isRedirectProcessed = false;

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    initializeApp(ENV.FIREBASE_CONFIG);
    this.auth = getAuth();
    this.auth.config.apiHost = `${getHostName()}/apigateway/google/identityplatform`;
  }

  async handleRedirect() {
    if (this.isRedirectProcessed) {
      return null;
    }

    this.isRedirectProcessed = true;
    const result = await getRedirectResult(this.auth);
    if (result && result.user) {
      const idToken = await result.user.getIdToken();
      const params = {
        next: this.nextWithDefault,
      };
      const loginResult = await this.verifyTokenOnUM(idToken, params);
      loginResult.email = result.user.email;
      loginResult.token = idToken;
      return loginResult;
    }
    return null;
  }

  async signInWithPassword(email: string, password: string, params: Record<string, unknown> = {}) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      const { user } = userCredential;
      const idToken = await user.getIdToken();
      const loginResponse = await this.verifyTokenOnUM(idToken, params);
      loginResponse.token = idToken;
      return loginResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return signInWithRedirect(this.auth, provider);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signInWithMicrosoft() {
    try {
      const provider = new OAuthProvider('microsoft.com');
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return await signInWithRedirect(this.auth, provider);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signInWithSaml(gipName: string) {
    try {
      const provider = new SAMLAuthProvider(gipName);
      return await signInWithRedirect(this.auth, provider);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async verifyTokenOnUM(token: string, params: Record<string, unknown> = {}) {
    return this.fetchService.post<LoginResponse>('/sso/login/', params, {
      token,
    });
  }

  async acceptSso(token: string, gipName: GIPName | string) {
    return fetch(`${getHostDomain()}/sso/accept-sso/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': (
          document.querySelector('input[name="csrfmiddlewaretoken"]') as HTMLInputElement
        )?.value,
      },
      body: JSON.stringify({
        token,
        gip_name: gipName,
      }),
    });
  }

  get nextWithDefault() {
    const { queryParams } = this.router.currentRoute;
    if (queryParams['next'] && queryParams['next'].startsWith('/')) {
      return queryParams['next'];
    }

    return '/';
  }
}
