/* import __COLOCATED_TEMPLATE__ from './button-single-sign-on.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

import importAsset from 'frontend-login/helpers/import-asset';
import { SSO_TYPES, type SSOType } from 'frontend-login/utils/constant-values';

export interface ButtonSingleSignOnSignature {
  Args: {
    // type defines if it is a Google, Linkedin, Microsoft or SAML button
    type: SSOType;
    isLoading: boolean;
    isDisabled?: boolean;
    onClick?: (event: MouseEvent) => void | unknown;
  };
  Element: HTMLButtonElement;
}

export default class ButtonSingleSignOn extends Component<ButtonSingleSignOnSignature> {
  @service declare intl: IntlService;

  get formattedLabel() {
    if (this.args.type === SSO_TYPES.SAML) {
      return this.intl.t('login.single_sign_on');
    }

    return this.args.type;
  }

  get icon() {
    if (this.args.isDisabled) {
      return importAsset(`images/sso/${this.args.type}-disabled.svg`);
    }

    return importAsset(`images/sso/${this.args.type}.svg`);
  }

  @action
  onClick(event: MouseEvent) {
    this.args.onClick?.(event);
  }
}
