import EmberRouter from '@ember/routing/router';

import config from 'frontend-login/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;

  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('password-reset');
  this.route('login');
  this.route('register');
  this.route('change-email', { path: '/change-email/:code/' });

  return true;
});
