import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

import getLanguageFromBrowser from 'frontend-utils/utils/browser-language';

// Seems like a missing key in the @types/ember__routing package
type TransitionWithTargetName = Transition & { targetName: string };

export default class ApplicationRoute extends Route {
  @service declare router: RouterService;

  @service declare intl: IntlService;

  redirect(model: void, transition: TransitionWithTargetName) {
    if (transition.targetName === 'index') {
      this.router.transitionTo('login');
    }
  }

  async beforeModel() {
    const language = getLanguageFromBrowser();
    this.intl.locale = language;
  }
}
