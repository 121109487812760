import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';

import type LoginController from 'frontend-login/controllers/login';

export default class LoginRoute extends Route {
  queryParams = {
    next: {
      refreshModel: false,
    },
    idp: {
      refreshModel: false,
    },
  };

  @service declare router: RouterService;

  setupController(controller: LoginController, transition: Transition) {
    super.setupController(controller, {}, transition);

    if (controller.email) {
      controller.emailInputValue = controller.email;
    }

    if (controller.idp) {
      controller.redirectToIdp(controller.idp);
      controller.idp = null;
      this.router.replaceWith('login', { queryParams: { idp: null } });
    }
  }
}
